import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../layouts'
import GatsbyLink from 'gatsby-link'
import '../css/style.css'
import { respondTo, mapDetailTables } from '../utils'

import DefinitionsTable from '../components/DefinitionsTable'
import PageSection from '../components/PageSection'
import Tabs from '../components/Tabs'
import { Wrapper } from '../styledComponents'

const TestDetail = ({ data }) => {
  const PageTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.blue};
    padding: 0 0 1rem 1rem;

    ${respondTo.small`
      padding: 0 0 1rem 2rem;
    `}
  `

  const Crumb = styled(GatsbyLink)`
    font-size: 1rem;
    padding: 0 1rem;

    ${respondTo.small`
      font-size: 0.7rem;
      padding: 0 2rem;
    `}
  `

  //Generate Table structure
  const tables = mapDetailTables(data.pageData)
  const titel = data.pageData.titel
  return (
    <Layout>
      <Wrapper>
        <Crumb to="/catalogusAZ"> &larr; Terug naar index</Crumb>
        <PageTitle>{titel}</PageTitle>
        <Tabs
          buttons={[
            'Aanvraaginformatie',
            'Materiaal afname/opslag',
            'Interpretatie',
            'Testeigenschappen',
          ]}
        >
          <PageSection
            sectionHeading={tables.algemeen.sectionHeading}
            noPadding
            flex={2}
            color="lightBeige"
          >
            <DefinitionsTable tableData={tables.algemeen.firstColumn} />
            <DefinitionsTable tableData={tables.algemeen.secondColumn} />
          </PageSection>
          <PageSection
            sectionHeading={tables.verzenden.sectionHeading}
            flex={2}
            noPadding
          >
            <DefinitionsTable tableData={tables.verzenden.firstColumn} />
            <DefinitionsTable tableData={tables.verzenden.secondColumn} />
          </PageSection>
          <PageSection
            sectionHeading={tables.interpretatie.sectionHeading}
            flex
            noPadding
            color="lightBeige"
          >
            <DefinitionsTable tableData={tables.interpretatie.firstColumn} />
          </PageSection>
          <PageSection
            sectionHeading={tables.behouden.sectionHeading}
            flex={2}
            noPadding
          >
            <DefinitionsTable tableData={tables.behouden.firstColumn} />
            <DefinitionsTable tableData={tables.behouden.secondColumn} />
          </PageSection>
        </Tabs>
      </Wrapper>
    </Layout>
  )
}

export default TestDetail

export const pageQuery = graphql`
  query testDetailQuery($contentful_id: String!) {
    pageData: contentfulTestItem(contentful_id: { eq: $contentful_id }) {
      aanvraagcode
      aanvraagformulier {
        formuliernummer
        contactgegevens
        verzendingNaar
        weblink
        titel
      }
      afdeling {
        afdeling
      }
      afnameVolume
      afnamecondities
      analyseFrequentie {
        frequentie
      }
      benodigdeInformatie
      bewaarcondities
      bewaarperiode
      bronvermelding {
        childMarkdownRemark {
          html
        }
      }
      citoAanvraagbaar
      contactpersoon {
        contactpersoon
        email
        telefoonnummer
      }
      doorbelgrenzen
      doorlooptijd
      eenheid
      indicatie
      interpretatie {
        childMarkdownRemark {
          html
        }
      }
      labgidsId
      lisCode
      minimaleAfnamehoeveelheid
      opmerkingen {
        childMarkdownRemark {
          html
        }
      }
      referentiewaarden {
        childMarkdownRemark {
          html
        }
      }
      synoniemen
      testeigenschappen {
        childMarkdownRemark {
          html
        }
      }
      titel
      verzendcondities
      materiaal {
        code
        titel
        kleurDop
        afbeelding {
          fluid(maxWidth: 720, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      alternatiefMateriaal {
        code
        titel
        kleurDop
        afbeelding {
          fluid(maxWidth: 720, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      methode {
        methode
      }
      tarief {
        klasse
        tarief
      }
      verzendformulier {
        formuliernummer
        contactgegevens
        verzendingNaar
        weblink
        titel
      }
    }
  }
`
